import { Component, OnInit } from '@angular/core';
import {AuthService, SocialUser} from 'angularx-social-login';
import {Weather} from '../../core/models/weather';
import {PostsService} from '../../core/posts.service';
import {CategoriesService} from '../../core/categories.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AuthorizationService} from '../../core/authorization.service';
import {WeatherService} from '../../core/weather.service';
import {EventService} from '../../core/event.service';
import {UsersService} from '../../core/users.service';


@Component({
  selector: 'jz-weather-card',
  templateUrl: './weather-card.component.html',
  styleUrls: ['./weather-card.component.scss'],
})
export class WeatherCardComponent implements OnInit {
  user: SocialUser;
  weather: Weather;
  timezone = 'UTC -5';
  selectedCityName = 'Plantation'; // initialization needed to ternary operator in local time date
  name: string;
  today = new Date();

  cities = [
    { name: 'Bristol', timezone: 'UTC -5' },
    { name: 'Bentonville', timezone: 'UTC -6' },
    { name: 'Culver City', timezone: 'UTC -8' },
    { name: 'Darmstadt', timezone: 'UTC +1' },
    { name: 'Dongguan', timezone: 'UTC +8' },
    { name: 'Hong Kong', timezone: 'UTC +8' },
    { name: 'Hanoi', timezone: 'UTC +7' },
    {
      name: 'Kingdom of the Netherlands, NL',
      displayName: 'Holland',
      timezone: 'UTC +1',
    },
    { name: 'London', timezone: 'UTC 0' },
    { name: 'Los Angeles', timezone: 'UTC -8' },
    { name: 'Mexico City', timezone: 'UTC -6' },
    { name: 'Minneapolis', timezone: 'UTC -6' },
    { name: 'New York', timezone: 'UTC -5' },
    { name: 'Pacific', timezone: 'UTC -8' },
    { name: 'Paris', timezone: 'UTC +1' },
    { name: 'Plantation', timezone: 'UTC -5' },
    { name: 'Toronto', timezone: 'UTC -5' },
    { name: 'Melbourne,AU', displayName: 'Melbourne', timezone: 'UTC +11' },
    {
      name: 'Kowloon,HK',
      displayName: 'Kowloon',
      timezone: 'UTC +8',
    },
    { name: 'Cerritos', timezone: 'UTC -8' },
    { name: 'Seattle', timezone: 'UTC -8' },
    {
      name: 'Sumner',
      id: "5812543",
      timezone: 'UTC -8',
    },
    { name: 'Bilbao,ES', displayName: 'Bilbao', timezone: 'UTC +2' },
  ];
  months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };
  day: number;
  month: number;
  year: number;

  constructor(
    private authService: AuthService,
    private postsService: PostsService,
    private categoriesService: CategoriesService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private modalService: BsModalService,
    private googleLoginService: AuthorizationService,
    private weatherService: WeatherService,
    private eventService: EventService,
    private usersService: UsersService
  ) {}

  ngOnInit(): void {
    // user

    if (!this.googleLoginService.user) {
      this.authService.authState.subscribe((user) => {
        this.user = user;
        this.googleLoginService.setLoggedInUser(user);
      });
    } else {
      this.user = this.googleLoginService.user;
    }

    // City
    this.getCity('Plantation', 'UTC -5');

    // dates calculation
    this.day = this.today.getDate();
    this.month = this.today.getMonth() + 1;
    this.year = this.today.getFullYear();

    this.sortArray();
  }

  // weather
  getCity(city, timezone: string,id?:string) {
    this.weatherService
      .getWeatherDataByCityName(city,id)
      .subscribe((data: Weather) => {
        this.weather = data;
        const date = new Date(Date.now() + 1000 * data.timezone);
        const hours = date.getUTCHours();
        let hours_12 = (hrs = hours) => {
          return hrs > 12 ? hrs - 12 : hrs === 0 ? 12 : hrs;
        };
        const minutes = (mins = date.getUTCMinutes()) => {
          return mins < 10 ? `0${mins}` : `${mins}`;
        };
        const ampm = hours >= 12 ? 'PM' : 'AM';
        this.timezone = hours_12().toString() + ':' + minutes() + ampm;
        console.log({
          weather: data,
          city: data.name,
          time: this.timezone,
          date,
          hours,
        });
      });
    // this.timezone = timezone;
  }

  setCityWeather(city: string, timezone: string,id?:string) {
    this.getCity(city, timezone,id);
    this.selectedCityName = this.city(city);
    console.log(this.selectedCityName);
  }

  city(_city: string) {
    let hasDisplayName = this.cities.find((cty) => {
      return cty.name === _city && cty.displayName;
    });
    console.log({ city: hasDisplayName, _city });
    return hasDisplayName ? hasDisplayName.displayName : _city;
  }
  convertDateFromIntToString(month) {
    const result = this.months[month];
    return result;
  }

  sortArray() {
    this.cities.sort((a, b) => a.name.localeCompare(b.name));
  }
}
